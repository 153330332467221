// Dark color mode variables
//
// Custom variables for the `[data-bs-theme="dark"]` theme. Use this as a starting point for your own custom color modes by creating a new theme-specific file like `_variables-dark.scss` and adding the variables you need.


$konkon-text-emphasis-dark:  tint-color($konkon, 40%);
$konkon-bg-subtle-dark:      shade-color($konkon, 80%);
$konkon-border-subtle-dark:  shade-color($konkon, 40%);
$konkon-text-dark:           tint-color($konkon, 40%);


//
// Global colors
//

$primary-text-emphasis-dark:        tint-color($primary, 40%) !default;

$body-color-dark: $gray-400 !default;

// scss-docs-start type-variables-dark
$mark-color-dark: $konkon-text-emphasis-dark;
$mark-bg-dark:    $konkon-bg-subtle-dark;
$code-color-dark: $konkon-text-emphasis-dark;
// scss-docs-end type-variables-dark


//
// Forms
//

$form-select-indicator-color-dark:  $body-color-dark !default;
$form-select-indicator-dark:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color-dark}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg>") !default;

$form-switch-color-dark:            rgba($white, .25) !default;
$form-switch-bg-image-dark:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color-dark}'/></svg>") !default;


//
// Accordion
//

$accordion-icon-color-dark:         $primary-text-emphasis-dark !default;
$accordion-icon-active-color-dark:  $primary-text-emphasis-dark !default;

$accordion-button-icon-dark:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color-dark}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
$accordion-button-active-icon-dark:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color-dark}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
