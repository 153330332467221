// Required Imports
@import "core/bootstrap-konkon/bootstrap-required";

// Page Header
$page-header-h: 30vh;
$page-header-mh: 250px;
$page-header-pb: $page-header-mh * .15;
$page-header-list-top: $page-header-pb * -1;

$variable-prefix: sk-;

:root {
   --#{$variable-prefix}header-color: #212529;
   --#{$variable-prefix}header-brightness: 80%;
}

.homepage-header {
   height: $page-header-h;
   min-height: $page-header-mh;
   background-color: var(--#{$variable-prefix}header-color);
   background-repeat: no-repeat;
   background-position: center center;
   background-size: cover;

   &-content {
      backdrop-filter: brightness(var(--#{$variable-prefix}header-brightness));
      height: inherit;
      min-height: inherit;
      padding-bottom: $page-header-pb;
   }

   &-list {
      transform: translate(0, $page-header-list-top);
   }
}

// Button and Link Focus

// Button and Link Focus
   
.btn-link {  
   position: relative;
   
   .btn-animate-icon {
      font-size: 1.25em;
      vertical-align: -0.2em;
      transition: $transition-base;
   }
      
   &:after {
      content: "";
      display: block;
      width: 60%;
      position: absolute;
      top: 100%;
      left: 0;
      border-bottom: 3px dashed currentColor;
      opacity: 0;
      transition: $transition-fade;
   }

   &:hover,
   &:focus {
      .btn-animate-icon {
         transform: translate(50%, 0%);
      }
   }
   
   &:focus {
      outline: none;
      box-shadow: none;
      
      &:after {
         opacity: .5;
      }
   }
}

// YouTube Section
.section-youtube {
   .card-img-top {
      margin-bottom: map-get($spacers, 2);
   }
   
   .card-title {
      display: block;
      display: box;
      display: -webkit-box;
      font-size: map-get($font-sizes, 6);
      line-height: $line-height-base;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      white-space: normal;
      padding: 0 map-get($spacers, 1);
      margin: 0;
   }
}
