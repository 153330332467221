@mixin switch-variant(
	$active-color,
	$focus-color: rgba($active-color, $input-btn-focus-color-opacity),
	$box-shadow: 0 0 0 $input-btn-focus-width $focus-color
) {
	.form-check-input {
		&:checked {
			background-color: $active-color;
			border-color: $active-color;
		}
	
		&:focus {
			border-color: shift-color($active-color, -50%);
			box-shadow: $box-shadow;
		}
	}
}
