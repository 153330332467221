// scss-docs-start sk-caret-mixins
@mixin caret(
   $direction: down,
   $width: $sk-caret-width,
   $size: $sk-caret-size,
   $spacing: $sk-caret-spacing,
   $vertical-align: 0
) {
   @if enable-caret {
      display: inline-flex;
      align-items: center;
      white-space: nowrap;
   
      &::after {
         content: "";
         display: inline-block;
         width: $size;
         height: $size;
         margin: 0;
         border-top: $width solid;
         border-left: none;
         border-bottom: none;
         border-right: $width solid;
         box-sizing: border-box;
         vertical-align: 0;
         
         @if $direction == down {
            transform: rotate(135deg);
            margin-top: -($size * .5);
            vertical-align: $size * .5;
            margin-left: $spacing + ($size * .25);
         } @else if $direction == up {
            transform: rotate(-45deg);
            margin-top: $size * .5;
            margin-left: $spacing + ($size * .25);
         } @else if $direction == end {
            transform: rotate(45deg);
            margin-left: $spacing;
         }
      }
      
      @if $direction == end {
         &.dropdown-toggle-split::after {
            margin-left: -($size * .25);
            margin-right: ($size * .25);
         }
      }

      @if $direction == start {
         &::after {
            display: none;
         }

         &::before {
            content: "";
            display: inline-block;
            width: $size;
            height: $size;
            margin: 0;
            margin-right: $spacing;
            border-top: $width solid;
            border-left: none;
            border-bottom: none;
            border-right: $width solid;
            box-sizing: border-box;
            transform: rotate(-135deg);
         }
            
         &.dropdown-toggle-split::before {
            margin-left: ($size * .25);
            margin-right: -($size * .25);
         }
      }

      &:empty::after {
         margin-left: 0;
      }
   }
}
// scss-docs-end sk-caret-mixins

