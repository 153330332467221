//Full colored modal
@mixin dropdown-color(
   $background,
   $color: color-contrast($background)
) {
   --#{$prefix}dropdown-color: #{$color};
   --#{$prefix}dropdown-tools-rgb: #{to-rgb($color)};
   --#{$prefix}dropdown-bg: #{$background};
   --#{$prefix}dropdown-border-color: transparent;
   --#{$prefix}dropdown-divider-bg: #{rgba($color, .25)};
   --#{$prefix}dropdown-link-color: #{$color};
   --#{$prefix}dropdown-link-hover-color: #{$color};
   --#{$prefix}dropdown-link-hover-bg: #{rgba($color, .1)};
   --#{$prefix}dropdown-link-active-color: #{$color};
   --#{$prefix}dropdown-link-active-bg: #{rgba(color-contrast($color), .1)};
   --#{$prefix}dropdown-link-disabled-color: #{rgba($color, .5)};
   --bs-dropdown-header-color: #{rgba($color, .75)};
}
